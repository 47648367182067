import { mapByScoreToRecommendations, uniqBy } from './utils';
import { version } from './version';
export function getTrendingItems(_ref) {
  var recommendClient = _ref.recommendClient,
      _ref$transformItems = _ref.transformItems,
      transformItems = _ref$transformItems === void 0 ? function (x) {
    return x;
  } : _ref$transformItems,
      fallbackParameters = _ref.fallbackParameters,
      indexName = _ref.indexName,
      maxRecommendations = _ref.maxRecommendations,
      queryParameters = _ref.queryParameters,
      threshold = _ref.threshold,
      facetName = _ref.facetName,
      facetValue = _ref.facetValue;
  var query = {
    fallbackParameters: fallbackParameters,
    indexName: indexName,
    maxRecommendations: maxRecommendations,
    queryParameters: queryParameters,
    threshold: threshold,
    facetName: facetName,
    facetValue: facetValue
  };
  recommendClient.addAlgoliaAgent('recommend-core', version);
  return recommendClient.getTrendingItems([query]).then(function (response) {
    return mapByScoreToRecommendations({
      maxRecommendations: maxRecommendations,
      // Multiple identical recommended `objectID`s can be returned b
      // the engine, so we need to remove duplicates.
      hits: uniqBy('objectID', response.results.map(function (result) {
        return result.hits;
      }).flat())
    });
  }).then(function (hits) {
    return {
      recommendations: transformItems(hits)
    };
  });
}