import { sortBy } from './sortBy';
export function mapByScoreToRecommendations(_ref) {
  var hits = _ref.hits,
      maxRecommendations = _ref.maxRecommendations;
  // Since recommendations from multiple indices are returned, we
  // need to sort them descending based on their score.
  return sortBy(function (a, b) {
    var scoreA = a._score || 0;
    var scoreB = b._score || 0;
    return scoreA > scoreB ? -1 : 1;
  }, hits).slice(0, // We cap the number of recommendations because the previously
  // computed `hitsPerPage` was an approximation due to `Math.ceil`.
  maxRecommendations && maxRecommendations > 0 ? maxRecommendations : undefined);
}