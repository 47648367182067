import { mapByScoreToRecommendations } from './utils';
import { version } from './version';
export function getTrendingFacets(_ref) {
  var recommendClient = _ref.recommendClient,
      _ref$transformItems = _ref.transformItems,
      transformItems = _ref$transformItems === void 0 ? function (x) {
    return x;
  } : _ref$transformItems,
      indexName = _ref.indexName,
      maxRecommendations = _ref.maxRecommendations,
      threshold = _ref.threshold,
      facetName = _ref.facetName;
  var query = {
    indexName: indexName,
    maxRecommendations: maxRecommendations,
    threshold: threshold,
    facetName: facetName
  };
  recommendClient.addAlgoliaAgent('recommend-core', version);
  return recommendClient.getTrendingFacets([query]).then(function (response) {
    return mapByScoreToRecommendations({
      maxRecommendations: maxRecommendations,
      hits: response.results.map(function (result) {
        return result.hits;
      }).flat()
    });
  }).then(function (hits) {
    return {
      recommendations: transformItems(hits)
    };
  });
}